<template>
    <b-card>
        <div class="row">
            <div class="col-sm-4">
                <h5>
                    Total cursos
                </h5>
            </div>
            <div class="col-sm-4">
                <h5>
                    Total estudiantes
                </h5>
            </div>
            <div class="col-sm-4">
                <h5>
                    Total facturado
                </h5>
            </div>
        </div>
    </b-card>
</template>